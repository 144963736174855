const colleges = {
    100: "MAKAUT, WB (In House)",
    103: "HALDIA INSTITUTE OF TECHNOLOGY ICARE COMPLEX",
    104: "INSTITUTE OF ENGINEERING & MANAGEMENT",
    105: "BANKURA UNNAYANI INSTITUTE OF ENGINEERING",
    106: "MURSHIDABAD COLLEGE OF ENGINEERING & TECHNOLOGY",
    107: "COLLEGE OF ENGINEERING & MANAGEMENT",
    108: "ASANSOL ENGINEERING COLLEGE VIVEKANANDA SARANI",
    109: "NETAJI SUBHASH ENGINEERING COLLEGE",
    110: "GOVT. COLLEGE OF ENGG. & TEXTILE",
    111: "GOVT. COLLEGE OF ENGINEERING AND TEXTILE TECHNOLOGY",
    112: "GOVERNMENT COLLEGE OF ENGINEERING AND LEATHER TECHNOLOGY",
    113: "GOVERNMENT COLLEGE OF ENGINEERING & CERAMIC TECHNOLOGY",
    115: "B.P. PODDAR INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    116: "MCKV INSTITUTE OF ENGINEERING COLLEGE",
    117: "RCC INSTITUTE OF INFORMATION TECHNOLOGY",
    118: "BIRBHUM INSTITUTE OF ENGINEERING",
    119: "SILIGURI INSTITUTE OF TECHNOLOGY HILL CART ROAD",
    120: "DR. B. C. ROY ENGINEERING COLLEGE",
    121: "BENGAL INSTITUTE OF TECHNOLOGY TECH TOWN",
    122: "ST. THOMAS COLLEGE OF ENGINEERING & TECHNOLOGY",
    123: "JIS COLLEGE OF ENGINEERING BLOCK-A",
    124: "GUPTA COLLEGE OF TECHNOLOGICAL",
    125: "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    126: "HERITAGE INSTITUTE OF TECHNOLOGY",
    127: "NARULA INSTITUTE OF TECHNOLOGY",
    129: "BHARTIYA VIDYA BHAVAN INSTITUTE",
    130: "TECHNO MAIN SALT LAKE",
    131: "DUMKAL INSTITUTE OF ENGINEERING",
    132: "BHOLANANDA NATIONAL ACADEMY VILLAGE: PANPUR",
    133: "GEORGE COLLEGE 136 B. B. GANGULY STREET",
    134: "GITARAM INSTITUTE OF MANAGEMENT BERHAMPORE",
    136: "ARMY INSTITUTE OF MANAGEMENT JUDGES COURT ROAD OPPOSITE",
    137: "INSTITUTE OF BUSINESS MANAGEMENT",
    139: "VIDYASAGAR COLLEGE OF OPTOMETRY & VISION SCIENCE",
    142: "MEGHNAD SAHA INSTITUTE OF TECHNOLOGY",
    143: "GURUNANAK INSTITUTE OF TECHNOLOGY",
    144: "NEOTIA INSTITUTE OF TECHNOLOGY",
    145: "TECHNO INDIA INSTITUTE OF TECHNOLOGY",
    148: "FUTURE INSTITUTE OF ENGINEERING",
    149: "NSHM COLLEGE OF MANAGEMENT & TECHNOLOGY",
    150: "NOPANY INSTITUTE OF MANAGEMENT",
    152: "TECHNO INDIA (HOOGHLY CAMPUS) DHARAMPUR SANTINIKATAN",
    153: "SYAMAPRASAD INST. OF TECH. & MANAGEMENT",
    154: "DINABANDHU ANDREWS INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    155: "DURGAPUR INSTITUTE OF ADVANCED TECHNOLOGY & MANAGEMENT",
    156: "PAILAN COLLEGE OF MANAGEMENT & TECHNOLOGY [B.TECH DIVISION]",
    158: "MALLABHUM INSTITUTE OF TECHNOLOGY",
    159: "CALCUTTA INSTITUTE OF PHARMACEUTICAL TECHNOLOGY & ALLIED HEALTH SCIENCES",
    160: "NATIONAL INSTITUTE OF TECHNICAL TEACHERSâ€™ TRAINING & RESEARCH",
    161: "PARAMEDICAL COLLEGE",
    163: "BENGAL INSTITUTE OF TECHNOLOGY",
    164: "GEORGE COLLEGE OF MANAGEMENT AND SCIENCE",
    165: "CALCUTTA INSTITUTE OF ENGINEERING",
    168: "SAROJ MOHAN INSTITUTE OF TECHNOLOGY",
    169: "ACADEMY OF TECHNOLOGY G.T. ROAD",
    170: "IMPS COLLEGE OF ENGINEERING & TECHNOLOGY",
    173: "KINGSTON SCHOOL OF MANAGEMENT AND SCIENCE",
    174: "NETAJI SUBHAS CHANDRA BOSEINSTITUTE OF PHARMACY",
    175: "HALDIA INSTITUTE OF MANAGEMENT P.O. HATIBERIA",
    176: "HOOGHLY ENGINEERING & TECHNOLOGY COLLEGE",
    177: "CALCUTTA INSTITUTE OF TECHNOLOGY",
    178: "INTERNATIONAL INSTITUTE OF MANAGEMENT SCIENCES",
    179: "HALDIA INSTITUTE OF MARITIME",
    180: "NIPS SCHOOL OF HOTEL MANAGEMENT",
    182: "INSTITUTE OF SCIENCE AND TECHNOLOGY",
    183: "EASTERN INSTITUTE FOR INTEGRATED",
    185: "GURUNANAK INSTITUTE OF HOTEL MANAGEMENT",
    186: "GURU NANAK INSTITUTE OF PHARMACEUTICAL SCIENCE & TECHNOLOGY",
    187: "TECHNO INTERNATIONAL NEW TOWN MEGA CITY",
    188: "TECHNO COLLEGE HOOGHLY",
    189: "DR. B. C. ROY COLLEGE OF PHARMACY",
    192: "GLOBAL INSTITUTE OF SCIENCE & TECHNOLOGY",
    193: "BENGAL SCHOOL OF TECHNOLOGY SUGANDHA",
    194: "INSTITUTE OF MANAGEMENT STUDY 93 MUKUNDAPUR MAIN ROAD",
    195: "THE CALCUTTA ANGLO GUJARATI",
    198: "ADVANCED INFORMATION & MANAGEMENT STUDIES",
    199: "DOOARS ACADEMY OF TECHNOLOGY",
    200: "GOLDEN REGENCY INSTITUTE OF DEBHOG",
    201: "BCDA COLLEGE OF PHARMACY & TECHNOLOGY",
    202: "KOTIBARSHA INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    206: "SEACOM ENGINEERING COLLEGE JALADHULAGORI SANKRAIL",
    208: "BHARAT TECHNOLOGY JADURBERIA",
    209: "DREAM INSTITUTE OF TECHNOLOGY",
    210: "INSTITUTE OF GENETIC ENGINEERING THAKURHAT ROAD",
    211: "POST GRADUATE INSTITUTE OF HOSPITAL ADMINISTRATION",
    212: "TAMRALIPTA INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    213: "THE HERITAGE ACADEMY CHOWBAGA ROAD",
    214: "SEACOM MANAGEMENT COLLEGE JALADHULAGORI",
    215: "NIMAS",
    216: "ABS ACADEMY OF SCIENCE TECHNOLOGY & MANAGEMENT",
    220: "PRAJNANANANDA INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    221: "GURUKUL MANAGEMENT STUDIES MOUZA-NARAYANPUR",
    226: "TECHNO INDIA",
    229: "DURGAPUR INSTITUTE OF SCIENCE TECHNOLOGY AND MANAGEMENT",
    230: "CAMELLIA INSTITUTE OF TECHNOLOGY DIGBERIA",
    234: "NSHM COLLEGE OF MANAGEMENT & TECHNOLOGY",
    235: "SEACOM MARINE COLLEGE JALADHULAGORI",
    236: "GREATER KOLKATA COLLEGE OF ENGINEERING & MANAGEMENT",
    238: "CAMELLIA SCHOOL OF ENGINEERING",
    240: "ABACUS INSTITUTE OF ENGINEERING",
    241: "SWAMI VIVEKANANDA INSTITUTE OF SCIENCE & TECHNOLOGY",
    242: "BENGAL COLLEGE OF PHARMACEUTICAL SCIENCES & RESEARCH",
    243: "WEST BENGAL STATE COUNCIL FOR SCIENCE & TECHNOLOGY",
    244: "TECHNO ENGINEERING COLLEGE",
    246: "RINPOCHE ACADEMY OF MANAGEMENT AND TECHNOLOGY",
    250: "SWAMI VIVEKANANDA INSTITUTE OF MANAGEMENT & COMPUTER SCIENCE",
    252: "KANAD INSTITUTE OF ENGINEERING & MANAGEMENT",
    253: "SUPREME KNOWLEDGE FOUNDATION GROUP OF INSTITUTIONS",
    255: "DR. SUDHIR CHANDRA SUR INSTITUTE",
    257: "CAMELLIA INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    259: "GLOBAL INSTITUTE OF MANAGEMENT AND TECHNOLOGY",
    260: "BENGAL COLLEGE OF ENGINEERING SHILPO KANAN ROAD",
    261: "SURENDRA INSTITUTE OF ENGINEERING & MANAGEMENT",
    263: "REGENT EDUCATION & RESEARCH",
    264: "SWAMI VIVEKANANDA INSTITUTE OF MODERN SCIENCE",
    265: "DOLPHIN SCHOOL OF HOTEL MANAGEMENT",
    266: "BENGAL SCHOOL OF TECHNOLOGY & MANAGEMENT",
    269: "MODERN INSTITUTE OF ENGINEERING",
    271: "CAMELLIA INSTITUTE OF ENGINEERING",
    273: "NSHM KNOWLEDGE CAMPUS",
    275: "OMDAYAL GROUP OF INSTITUTIONS",
    276: "BUDGE BUDGE INSTITUTE OF TECHNOLOGY",
    277: "NSHM KNOWLEDGE CAMPUS",
    278: "SANAKA EDUCATIONAL TRUST",
    279: "IDEAL INSTITUTE OF ENGINEERING KALYANI SHILPANCHAL",
    281: "GARGI MEMORIAL INSTITUTE OF TECHNOLOGY CAMPUS",
    286: "ST. MARY'S TECHNICAL CAMPUS",
    287: "ROCKVALE MANAGEMENT COLLEGE 9",
    288: "DARJEELING UNIVERSAL CAMPUS TAKDAH CANTONMENT",
    289: "ILEAD (INSTITUTE OF LEADERSHIP)",
    290: "INSTITUTE OF HOTEL AND RESTAURANT MANAGEMENT",
    291: "NETAJI SUBHASH ENGINEERING COLLEGE",
    292: "NETAJI SUBHASH ENGINEERING COLLEGE",
    294: "FUTURE INSTITUTE OF ENGINEERING",
    296: "PAILAN COLLEGE OF MANAGEMENT & TECHNOLOGY (MBA DIVISION)",
    297: "PAILAN COLLEGE OF MANAGEMENT & TECHNOLOGY",
    298: "INSTITUTE OF BUSINESS MANAGEMENT",
    299: "CALCUTTA INSTITUTE OF ENGG. AND MANAGEMENT",
    300: "MAKAUT WB",
    301: "B. P. PODDAR INSTITUTE OF MANAGEMENT & TECHNOLOGY",
    304: "HERITAGE BUSINESS SCHOOL",
    306: "NARULA INSTITUTE OF TECHNOLOGY",
    309: "TECHNO INDIA",
    310: "MEGHNAD SAHA INSTITUTE OF TECHNOLOGY",
    311: "GURUNANAK INSTITUTE OF TECHNOLOGY",
    312: "GURUNANAK INSTITUTE OF HOTEL MANAGEMENT",
    313: "GURUNANAK INSTITUTE OF PHARMACEUTICAL SCIENCE & TECHNOLOGY",
    314: "TECHNO INDIA COLLEGE OF MEGHA CITY",
    318: "JIS COLLEGE OF ENGINEERING BLOCK A",
    320: "SAROJ MOHAN INSTITUTE OF TECHNOLOGY",
    321: "INSTITUTE OF SCIENCE & TECHNOLOGY DHURABILA",
    322: "ASANSOL ENGINEERING COLLEGE KANYAPUR",
    323: "DR. B. C. ROY ENGINEERING COLLEGE ACADEMY OF PROFESSIONAL",
    324: "BENGAL COLLEGE OF ENGINEERING & TECHNOLOGY",
    326: "MANAGEMENT INSTITUTE OF DURGAPUR",
    327: "ARYABHATTA INSTITUTE OF ENGINEERING & MANAGEMENT",
    328: "ABS ACADEMY OF SCIENCE",
    329: "BENGAL INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    330: "MURSHIDABAD COLLEGE OF ENGINEERING AND TECHNOLOGY",
    331: "DUMKAL INSTITUTE OF ENGINEERING",
    332: "TECHNO INTERNATIONAL BATANAGAR PUTKHALI",
    333: "IMS BUSINESS SCHOOL VILL-MAKRAMPUR",
    334: "SILIGURI INSTITUTE OF TECHNOLOGY HILL CART ROAD",
    335: "SILIGURI INSTITUTE OF TECHNOLOGY",
    336: "SILIGURI INSTITUTE OF TECHNOLOGY",
    337: "GLOBAL GROUP OF INSTITUTIONS SRIKRISHNAPUR",
    338: "IIAS SCHOOL OF MANAGEMENT",
    339: "BHARATIYA VIDYA BHAVAN INSTITUTE",
    340: "CONTAI COLLEGE OF LEARNING & MANAGEMENT SCIENCE",
    341: "SBIHM SCHOOL OF MANAGEMENT JAGANNATHPUR ROAD",
    342: "FUTURE INSTITUTE OF TECHNOLOGY",
    343: "HEMNALINI MEMORIAL COLLEGE OF ENGINEERING",
    344: "INSPIRIA KNOWLEDGE CAMPUS HIMACHAL VIHAR",
    345: "GLOBAL COLLEGE OF SCIENCE AND TECHNOLOGY",
    346: "ELITTE COLLEGE OF ENGINEERING KARNAMADHABPUR ",
    347: "JLD ENGINEERING AND MANAGEMENT",
    348: "EMINENT COLLEGE OF MANAGEMENT",
    349: "COOCH BEHAR GOVERNMENT ENGINEERING COLLEGE",
    350: "RAMKRISHNA MAHATO GOVERNMENT ENGINEERING COLLEGE PURULIA",
    351: "SWAMI VIVEKANANDA INSTITUTE OF MODERN STUDIES",
    352: "P. G. INSTITUTE OF MEDICAL SCIENCES DHURABILA",
    353: "BIRBHUM PHARMACY SCHOOL BANDHERSOLE",
    354: "EMINENT COLLEGE OF PHARMACEUTICAL TECHNOLOGY",
    355: "GHANI KHAN CHOUDHURY INSTITUTE",
    356: "GLOBAL COLLEGE OF PHARMACEUTICAL TECHNOLOGY",
    358: "SUBHAS BOSE INSTITUTE OF HOTEL MANAGEMENT",
    359: "SUSRUT EYE FOUNDATION & RESEARCH CENTRE",
    361: "WEBEL DQE ANIMATION ACADEMY WEBEL BHAVAN",
    362: "GITANJALI COLLEGE OF PHARMACY VILL+P.O.-LOHAPUR",
    363: "M.R. COLLEGE OF PHARMACEUTICAL",
    364: "SUPREME INSTITUTE OF MANAGEMENT",
    365: "ANAND COLLEGE OF EDUCATION VILL KABILPUR",
    366: "INSTITUTE OF ENGINEERING & MANAGEMENT",
    376: "BENGAL COLLEGE OF PHARMACEUTICAL TECHNOLOGY",
}
export default colleges;

const cources = {
    "052": "B.Tech Apparel & Production Management",
    "053": "B.Tech Applied Electronics & Instrumentation Engineering   ",
    "055": "B.Tech Applied Electronics & Instrumentation Engineering   ",
    "023": "Bachelor Architecture",
    "032": "B.Tech Automobile Engineering",
    "031": "B.Tech Biomedical Engineering",
    "004": "B.Tech Biotechnology",
    "006": "B.Tech Chemical Engineering",
    "054": "B.Tech Civil & Environmental Engineering",
    "013": "B.Tech Civil Engineering",
    "001": "B.Tech Computer Sc. & Engineering",
    "028": "B.Tech Electrical & Electronics Engineering",
    "016": "B.Tech Electrical Engineering",
    "003": "B.Tech Electronics & Communications Engg",
    "034": "B.Tech Food Technology",
    "022": "Bachelor Hotel Management & Catering Technology",
    "002": "B.Tech Information Technology",
    "040": "B.Tech Instrumentation & Control Engineering",
    "018": "B.Tech Leather Technology",
    "007": "B.Tech Mechanical Engineering",
    "019": "Bachelor of Pharmacy",
    "014": "B.Tech Textile Technology",
    "320": "B.Tech Robotics",
    "304": "B.Tech Data Science",
    "305": "B.Tech Computer Science and Engineering (Data Science)",
    "306": "B.Tech Artificial Intelligence and Machine Learning",
    "308": "B.Tech Computer Science And Engineering (AI & ML) ",
    "309": "B.Tech Computer Science And Engineering (IoT & Cyber Security including Block Chain Technology)",
    "310": "B.Tech Artificial Intelligence And Data Science",
    "311": "B.Tech Computer Science and Business System",
    "536": "Bachelor of Hospitality And Tourism Administration ",
    "315": "B.Tech Computer Science & Design (CSD)",
}
export const course_code = cources;

export const SELECTED_SERVER = "selectedServer";
export const HTTP_METHOD = "https://";
export const DEFAULT_SERVER = "gradecard.prasannathapa.in";
export let   CUSTOM_SERVER = localStorage.getItem(SELECTED_SERVER);
let API_HOST = HTTP_METHOD + DEFAULT_SERVER;
if(CUSTOM_SERVER){
    API_HOST = HTTP_METHOD + CUSTOM_SERVER;
}
function setHost(server) {
    if (server === DEFAULT_SERVER)
        API_HOST = HTTP_METHOD + DEFAULT_SERVER;
    else
        API_HOST = HTTP_METHOD + server;
}

export { API_HOST, setHost };

export const colors = [
    "#63b598", "#ce7d78", "#ea9e70", "#a48a9e", "#c6e1e8", "#648177", "#0d5ac1",
    "#f205e6", "#1c0365", "#14a9ad", "#4ca2f9", "#a4e43f", "#d298e2", "#6119d0",
    "#d2737d", "#c0a43c", "#f2510e", "#651be6", "#79806e", "#61da5e", "#cd2f00",
    "#9348af", "#01ac53", "#c5a4fb", "#996635", "#b11573", "#4bb473", "#75d89e",
    "#2f3f94", "#2f7b99", "#da967d", "#34891f", "#b0d87b", "#ca4751", "#7e50a8",
    "#c4d647", "#e0eeb8", "#11dec1", "#289812", "#566ca0", "#ffdbe1", "#2f1179",
    "#935b6d", "#916988", "#513d98", "#aead3a", "#9e6d71", "#4b5bdc", "#0cd36d",
    "#250662", "#cb5bea", "#228916", "#ac3e1b", "#df514a", "#539397", "#880977",
    "#f697c1", "#ba96ce", "#679c9d", "#c6c42c", "#5d2c52", "#48b41b", "#e1cf3b",
    "#5be4f0", "#57c4d8", "#a4d17a", "#225b8", "#be608b", "#96b00c", "#088baf",
    "#f158bf", "#e145ba", "#ee91e3", "#05d371", "#5426e0", "#4834d0", "#802234",
    "#6749e8", "#0971f0", "#8fb413", "#b2b4f0", "#c3c89d", "#c9a941", "#41d158",
    "#fb21a3", "#51aed9", "#5bb32d", "#807fb", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#21538e", "#89d534", "#d36647",
    "#7fb411", "#0023b8", "#3b8c2a", "#986b53", "#f50422", "#983f7a", "#ea24a3",
    "#79352c", "#521250", "#c79ed2", "#d6dd92", "#e33e52", "#b2be57", "#fa06ec",
    "#1bb699", "#6b2e5f", "#64820f", "#1c271", "#9cb64a", "#996c48", "#9ab9b7",
    "#06e052", "#e3a481", "#0eb621", "#fc458e", "#b2db15", "#aa226d", "#792ed8",
    "#73872a", "#520d3a", "#cefcb8", "#a5b3d9", "#7d1d85", "#c4fd57", "#f1ae16",
    "#8fe22a", "#ef6e3c", "#243eeb", "#1dc18", "#dd93fd", "#3f8473", "#e7dbce",
    "#421f79", "#7a3d93", "#635f6d", "#93f2d7", "#9b5c2a", "#15b9ee", "#0f5997",
    "#409188", "#911e20", "#1350ce", "#10e5b1", "#fff4d7", "#cb2582", "#ce00be",
    "#32d5d6", "#17232", "#608572", "#c79bc2", "#00f87c", "#77772a", "#6995ba",
    "#fc6b57", "#f07815", "#8fd883", "#060e27", "#96e591", "#21d52e", "#d00043",
    "#b47162", "#1ec227", "#4f0f6f", "#1d1d58", "#947002", "#bde052", "#e08c56",
    "#28fcfd", "#bb09b", "#36486a", "#d02e29", "#1ae6db", "#3e464c", "#a84a8f",
    "#911e7e", "#3f16d9", "#0f525f", "#ac7c0a", "#b4c086", "#c9d730", "#30cc49",
    "#3d6751", "#fb4c03", "#640fc1", "#62c03e", "#d3493a", "#88aa0b", "#406df9",
    "#615af0", "#4be47", "#2a3434", "#4a543f", "#79bca0", "#a8b8d4", "#00efd4",
    "#7ad236", "#7260d8", "#1deaa7", "#06f43a", "#823c59", "#e3d94c", "#dc1c06",
    "#f53b2a", "#b46238", "#2dfff6", "#a82b89", "#1a8011", "#436a9f", "#1a806a",
    "#4cf09d", "#c188a2", "#67eb4b", "#b308d3", "#fc7e41", "#af3101", "#ff065",
    "#71b1f4", "#a2f8a5", "#e23dd0", "#d3486d", "#00f7f9", "#474893", "#3cec35",
    "#1c65cb", "#5d1d0c", "#2d7d2a", "#ff3420", "#5cdd87", "#a259a4", "#e4ac44",
    "#1bede6", "#8798a4", "#d7790f", "#b2c24f", "#de73c2", "#d70a9c", "#25b67",
    "#88e9b8", "#c2b0e2", "#86e98f", "#ae90e2", "#1a806b", "#436a9e", "#0ec0ff",
    "#f812b3", "#b17fc9", "#8d6c2f", "#d3277a", "#2ca1ae", "#9685eb", "#8a96c6",
    "#dba2e6", "#76fc1b", "#608fa4", "#20f6ba", "#07d7f6", "#dce77a", "#77ecca"]

export const subCodes = {
    "BSCH101": ["SM01"],
    "BSCH191": ["SM01"],
    "BSM101": ["SM01"],
    "BSPH101": ["SM01"],
    "BSPH191": ["SM01"],
    "ESEE101": ["SM01"],
    "ESEE191": ["SM01"],
    "ESME191": ["SM01"],
    "ESME192": ["SM01"],
    "BSCH201": ["SM02"],
    "BSCH291": ["SM02"],
    "BSM201": ["SM02"],
    "BSPH201": ["SM02"],
    "BSPH291": ["SM02"],
    "ESCS201": ["SM02"],
    "ESCS291": ["SM02"],
    "ESME291": ["SM02"],
    "ESME292": ["SM02"],
    "HMHU201": ["SM02"],
    "HMHU291": ["SM02"],
    "BSC301": ["SM03"],
    "CH301": ["SM03"],
    "CS301": ["SM03"],
    "CS302": ["SM03"],
    "CS303": ["SM03"],
    "CS391": ["SM03"],
    "CS392": ["SM03"],
    "CS393": ["SM03"],
    "ES-CS391": ["SM03"],
    "ESC301": ["SM03"],
    "HSMC301": ["SM03"],
    "HU301": ["SM03"],
    "PCC-CS301": ["SM03"],
    "PCC-CS302": ["SM03"],
    "PCC-CS391": ["SM03"],
    "PCC-CS392": ["SM03"],
    "PCC-CS393": ["SM03"],
    "PH301": ["SM03"],
    "PH391": ["SM03"],
    "BSC 401": ["SM04"],
    "CS401": ["SM04"],
    "CS402": ["SM04"],
    "CS403": ["SM04"],
    "CS491": ["SM04"],
    "CS492": ["SM04"],
    "CS493": ["SM04"],
    "HU481": ["SM04"],
    "M401": ["SM04"],
    "MC401:": ["SM04"],
    "MCS401": ["SM04"],
    "MCS491": ["SM04"],
    "PCC-CS401": ["SM04"],
    "PCC-CS402": ["SM04"],
    "PCC-CS403": ["SM04"],
    "PCC-CS404": ["SM04"],
    "PCC-CS492": ["SM04"],
    "PCC-CS494": ["SM04"],
    "CS501": ["SM05"],
    "CS502": ["SM05"],
    "CS503": ["SM05"],
    "CS504D": ["SM05"],
    "CS591": ["SM05"],
    "CS592": ["SM05"],
    "CS593": ["SM05"],
    "CS594D": ["SM05"],
    "HU501": ["SM05"],
    "CS601": ["SM06"],
    "CS602": ["SM06"],
    "CS603": ["SM06"],
    "CS604B": ["SM06"],
    "CS605A": ["SM06"],
    "CS681": ["SM06"],
    "CS691": ["SM06"],
    "CS692": ["SM06"],
    "CS693": ["SM06"],
    "HU601": ["SM06"]
}
